import { v4 as uuidv4 } from 'uuid';

const TRACE_PARENT_KEY = 'traceparent';

export function getTraceParent(): string {
  let traceparent = sessionStorage.getItem(TRACE_PARENT_KEY);

  if (!traceparent) {
    const traceId = uuidv4().replace(/-/g, '').substring(0, 32);
    const spanId = uuidv4().replace(/-/g, '').substring(0, 16);
    traceparent = `00-${traceId}-${spanId}-01`;
    sessionStorage.setItem(TRACE_PARENT_KEY, traceparent);
  }
  return traceparent;
}
