import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { TCartItems } from '@mf/common/repositories/bff/cart/lastCart/types';
import { CartStore, Product, TShipping } from './cart.types';

export const useCartStore = create<CartStore>()(
  persist(
    (set) => ({
      cartItems: [], // TODO: Will be deprecated in the future in favor of `lastCartItems`

      addToCart: (item: Product) =>
        set((state: CartStore) => {
          return {
            cartItems: [...state.cartItems, item],
          };
        }),
      removeFromCart: (index: number, item: Product) =>
        set((state) => {
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            event: 'remove_from_cart',
            event_name: 'remove_from_cart',
            ecommerce: {
              currency: 'BRL',
              value: item.selectedQuantity.price,
              items: [
                {
                  item_id: item.final_product_id,
                  item_name: item.name,
                  affiliation: 'Printi',
                  discount: 0,
                  index: 0,
                  item_brand: 'Printi',
                  item_variant: item.selectedCombination.name,
                  price: item.selectedQuantity.price,
                  quantity: item.selectedQuantity.quantity,
                },
              ],
            },
          });

          return {
            cartItems: state.cartItems.filter((_, i) => i !== index),
          };
        }),

      clearCart: () =>
        set({ cartItems: [], shippingCost: 0, shippingQuote: null }),

      allRelatedProducts: [],
      relatedProductsByProduct: {},
      setRelatedProducts: (slug, relatedProducts) =>
        set((state) => {
          // Filtra os produtos que já estão em `allRelatedProducts` para evitar duplicatas
          const filteredRelatedProducts = relatedProducts.filter(
            (newProduct) =>
              !state.allRelatedProducts.some(
                (existingProduct) => existingProduct.slug === newProduct.slug,
              ),
          );

          return {
            relatedProductsByProduct: {
              ...state.relatedProductsByProduct,
              [slug]: relatedProducts,
            },
            allRelatedProducts: [
              ...state.allRelatedProducts,
              ...filteredRelatedProducts,
            ],
          };
        }),

      shippingQuote: null,
      setShippingQuote: (shippingQuote: TShipping[] | null) =>
        set({ shippingQuote }),

      shippingCost: 0,
      setShippingCost: (shippingCost: number) => set({ shippingCost }),

      cartSubtotal: 0,
      setCartSubtotal: (cartSubtotal: number) => set({ cartSubtotal }),

      cartTotal: 0,
      setCartTotal: (cartTotal: number) => set({ cartTotal }),

      cartDiscount: 0,
      setCartDiscount: (cartDiscount: number) => set({ cartDiscount }),

      loadingShippingQuote: false,
      setLoadingShippingQuote: (loadingShippingQuote) =>
        set({ loadingShippingQuote }),

      cartLoading: false,
      setCartLoading: (cartLoading) => set({ cartLoading }),

      offlineCartId: null,
      setOfflineCartId: (offlineCartId: string | null) =>
        set({ offlineCartId }),

      lastCartId: null,
      setLastCartId: (lastCartId: string | null) => set({ lastCartId }),

      lastCartItems: [],
      setLastCartItems: (lastCartItems: TCartItems[]) => set({ lastCartItems }),

      lastCartItemsTotal: 0,
      setLastCartItemsTotal: (lastCartItemsTotal: number) =>
        set({ lastCartItemsTotal }),

      resetLastCart: () =>
        set({
          lastCartId: null,
          lastCartItems: [],
          lastCartItemsTotal: 0,
        }),

      customerId: null,
      setCustomerId: (customerId: number | null) => set({ customerId }),
    }),
    {
      name: 'cart',
    },
  ),
);
