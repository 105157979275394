'use client';

type Breakpoint = 'xs' | 'mobile' | 'touch' | 'desktop' | 'widescreen';
type Breakpoints = Record<Breakpoint, string>;

const generateMedia = (breakpoints: Breakpoints) => {
  const getSizeFromBreakpoint = (
    breakpointValue: Breakpoint,
    breakpoints: Breakpoints,
  ) => breakpoints[breakpointValue];

  const lessThan = (breakpoint: Breakpoint) => {
    const width = getSizeFromBreakpoint(breakpoint, breakpoints);

    return `@media (max-width: ${width})`;
  };

  const greaterThan = (breakpoint: Breakpoint) => {
    const width = getSizeFromBreakpoint(breakpoint, breakpoints);

    return `@media (min-width: ${width})`;
  };

  const between = (
    firstBreakpoint: Breakpoint,
    secondBreakpoint: Breakpoint,
  ) => {
    const minWidth = getSizeFromBreakpoint(firstBreakpoint, breakpoints);
    const maxWidth = getSizeFromBreakpoint(secondBreakpoint, breakpoints);

    return `@media (min-width: ${minWidth}) and (max-width: ${maxWidth})`;
  };

  return {
    lessThan,
    greaterThan,
    between,
  };
};

export default generateMedia({
  xs: '25em', //400px
  mobile: '48em', //768px
  touch: '63.875em', // 1022px
  desktop: '63.9375em', // 1023px
  widescreen: '120em', // 1920px
});
