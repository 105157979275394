/* eslint-disable @typescript-eslint/no-explicit-any */
import { getTraceParent } from '../utils/tracing';
import { LogProvider } from './logProvider';

export const newRelicProvider: LogProvider = {
  trackEvent: (eventType, details = {}) => {
    if (typeof window === 'undefined') {
      return;
    }

    const traceparent = getTraceParent();
    const newrelic = window?.newrelic;

    if (!newrelic) {
      return;
    }

    newrelic.addPageAction(eventType, { ...details, traceparent });
  },

  logError: (error: any, details = {}) => {
    if (typeof window === 'undefined') {
      return;
    }

    const traceparent = getTraceParent();
    const newrelic = window?.newrelic;

    if (!newrelic) {
      return;
    }

    newrelic.noticeError(error, {
      ...details,
      traceparent,
    });
  },
};
