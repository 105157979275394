import { AxiosInstance } from 'axios';
import { api } from '@mf/common/config/axios/serviceApi';
import { TAxiosDefaultConfig } from '@mf/common/repositories/aecomStrapiCms/defaults/types';

const bffRemoveItem = ({ baseUrl, token }: TAxiosDefaultConfig) => {
  const apiBff: AxiosInstance = api({ baseUrl, token });

  const removeItem = async (cartId: string, itemId: string) => {
    const { data, status } = await apiBff.delete(
      `v1/carts/${cartId}/${itemId}`,
    );

    return {
      data,
      status,
    };
  };

  return {
    removeItem,
  };
};

export default bffRemoveItem;
