import { AxiosInstance } from 'axios';
import { api } from '@mf/common/config/axios/serviceApi';
import { TAxiosDefaultConfig } from '@mf/common/repositories/aecomStrapiCms/defaults/types';
import type { TLinkCustumerResponse } from './types';

const bffLinkCustumer = ({ baseUrl, token }: TAxiosDefaultConfig) => {
  const apiBff: AxiosInstance = api({ baseUrl, token });

  const linkCustomer = async (
    cartId: string,
  ): Promise<TLinkCustumerResponse | null> => {
    const { data, status } = await apiBff.post(
      `v1/carts/${cartId}/link-customer`,
    );

    return {
      data,
      status,
    };
  };

  return {
    linkCustomer,
  };
};

export default bffLinkCustumer;
