import { AxiosInstance } from 'axios';
import { api } from '@mf/common/config/axios/serviceApi';
import { TAxiosDefaultConfig } from '@mf/common/repositories/aecomStrapiCms/defaults/types';
import type { TAddItemPayload, TAddItemResponse } from './types';

const bffAddItem = ({ baseUrl, token }: TAxiosDefaultConfig) => {
  const apiBff: AxiosInstance = api({ baseUrl, token });

  const addItem = async (
    cartId: string,
    payload: TAddItemPayload,
  ): Promise<TAddItemResponse | null> => {
    const { data, status } = await apiBff.post(`v1/carts/${cartId}`, payload);

    return {
      data,
      status,
    };
  };

  return {
    addItem,
  };
};

export default bffAddItem;
