/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/order */
'use client';

import { createContext, useEffect } from 'react';

import { useCartStore } from '@mf/common/store/cart';
import bffLastCart from '@mf/common/repositories/bff/cart/lastCart';
import bffLinkCustomer from '@mf/common/repositories/bff/cart/linkCustomer';
import { TLastCartResponse } from '@mf/common/repositories/bff/cart/lastCart/types';
import { TCartDetailsResponse } from '@mf/common/repositories/bff/cart/cartDetails/types';

import {
  CartContextInit,
  CartContextProps,
  CartContextType,
  // TCreateCartResponse,
} from './CartContext.types';
import { useSession } from 'next-auth/react';
import { CustomSession } from '@mf/common/config/nextAuth';
import bffCreateCart from '@mf/common/repositories/bff/cart/createCart';
import bffAddItem from '@mf/common/repositories/bff/cart/addItem';
import {
  TAddItemPayload,
  TAddItemResponse,
} from '@mf/common/repositories/bff/cart/addItem/types';
import bffRemoveItem from '@mf/common/repositories/bff/cart/removeItem';
import bffCartDetails from '@mf/common/repositories/bff/cart/cartDetails';

export const CartContext = createContext<CartContextType>(CartContextInit);

export const CartContextProvider = ({
  children,
  payload,
}: CartContextProps) => {
  const { bffUrl } = payload;
  const { data: session, status } = useSession();
  const userSession = session as CustomSession;
  const token = userSession?.user?.token;

  const {
    customerId,
    lastCartId,
    lastCartItems,
    offlineCartId,
    setCartLoading,
    setCustomerId,
    setLastCartId,
    setLastCartItems,
    setLastCartItemsTotal,
    setOfflineCartId,
  } = useCartStore();

  const getLastCart = async (): Promise<TLastCartResponse | null> => {
    setCartLoading(true);
    const { lastCart } = bffLastCart({ baseUrl: bffUrl, token });
    const response = await lastCart();

    if (response) {
      setLastCartId(response.id);
      setCustomerId(response.customer_id);
      setLastCartItems(response.items);
      setLastCartItemsTotal(response.items.length || 0);
      setCartLoading(false);

      return response;
    }

    setCartLoading(false);

    return null;
  };

  const getCartDetails = async (
    cartId: string,
  ): Promise<TCartDetailsResponse | null> => {
    setCartLoading(true);
    const { cartDetails } = bffCartDetails({ baseUrl: bffUrl, token });
    const response = await cartDetails(cartId);

    if (response) {
      setLastCartItems(response.items);
      setLastCartItemsTotal(response.items.length || 0);
      setCartLoading(false);
    }

    setCartLoading(false);

    return null;
  };

  const createCart = async () => {
    const { createCart } = bffCreateCart({ baseUrl: bffUrl, token });
    const response = await createCart();

    if (response) {
      setLastCartId(response.id);

      return response;
    }

    return response;
  };

  const createOfflineCart = async () => {
    const { createCart } = bffCreateCart({ baseUrl: bffUrl, token });
    const response = await createCart();

    if (response) {
      setOfflineCartId(response.id);

      return response;
    }

    return response;
  };

  const addItemCart = async (
    cartId: string,
    itemToken: TAddItemPayload,
  ): Promise<TAddItemResponse | null> => {
    const { addItem } = bffAddItem({ baseUrl: bffUrl, token });
    const response = await addItem(cartId, itemToken);

    getCartDetails(cartId);

    return response;
  };

  const removeItemCart = async (cartId: string, itemId: string) => {
    const { removeItem } = bffRemoveItem({ baseUrl: bffUrl, token });
    const response = await removeItem(cartId, itemId);

    getCartDetails(cartId);

    return response;
  };

  const linkCustomerToCart = async (cartId: string) => {
    const { linkCustomer } = bffLinkCustomer({ baseUrl: bffUrl, token });
    const response = await linkCustomer(cartId);

    if (response) {
      setOfflineCartId(null);
      setLastCartId(cartId);
    }

    return response;
  };

  useEffect(() => {
    if (status === 'unauthenticated' && offlineCartId && !lastCartId) {
      getCartDetails(offlineCartId);
    }
  }, [status, offlineCartId, lastCartId]);

  useEffect(() => {
    if (status === 'authenticated' && token && offlineCartId && lastCartId) {
      getCartDetails(offlineCartId);
    }
  }, [status, token, offlineCartId, lastCartId]);

  useEffect(() => {
    if (status === 'authenticated' && token && lastCartId && !offlineCartId) {
      getCartDetails(lastCartId);
    }
  }, [status, lastCartId, offlineCartId]);

  useEffect(() => {
    if (status === 'authenticated' && token && offlineCartId) {
      linkCustomerToCart(offlineCartId);
    }
  }, [status, token, offlineCartId]);

  useEffect(() => {
    if (
      status === 'authenticated' &&
      token &&
      (!offlineCartId || !lastCartId)
    ) {
      getLastCart();
    }
  }, [status, token, offlineCartId, lastCartId]);

  return (
    <CartContext.Provider
      value={{
        addItemCart,
        createCart,
        createOfflineCart,
        customerId,
        getCartDetails,
        getLastCart,
        lastCartId,
        lastCartItems,
        linkCustomerToCart,
        offlineCartId,
        removeItemCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
