import { AxiosInstance } from 'axios';
import { api } from '@mf/common/config/axios/serviceApi';
import { TAxiosDefaultConfig } from '@mf/common/repositories/aecomStrapiCms/defaults/types';
import type { TCartDetailsResponse } from './types';

const bffCartDetails = ({ baseUrl, token }: TAxiosDefaultConfig) => {
  const apiBff: AxiosInstance = api({ baseUrl, token });

  const cartDetails = async (
    cartId: string,
  ): Promise<TCartDetailsResponse | null> => {
    const { data } = await apiBff.get(`v1/carts/${cartId}`);

    return data as TCartDetailsResponse;
  };

  return {
    cartDetails,
  };
};

export default bffCartDetails;
