import { AxiosInstance } from 'axios';
import { api } from '@mf/common/config/axios/serviceApi';
import { TAxiosDefaultConfig } from '@mf/common/repositories/aecomStrapiCms/defaults/types';
import type { TLastCartResponse } from './types';

const bffLastCart = ({ baseUrl, token }: TAxiosDefaultConfig) => {
  const apiBff: AxiosInstance = api({ baseUrl, token });

  const lastCart = async (): Promise<TLastCartResponse | null> => {
    try {
      const { data, status } = await apiBff.get('v1/carts/last');

      if (status === 200) {
        return data as TLastCartResponse;
      } else {
        if (status === 404) {
          return null;
        } else if (status === 500) {
          return null;
        } else {
          return null;
        }
      }
    } catch (error) {
      return null;
    }
  };

  return {
    lastCart,
  };
};

export default bffLastCart;
