import {
  TAddItemPayload,
  TAddItemResponse,
} from '@mf/common/repositories/bff/cart/addItem/types';
import { TCreateCartResponse } from '@mf/common/repositories/bff/cart/createCart/types';
import {
  TCartItems,
  TLastCartResponse,
} from '@mf/common/repositories/bff/cart/lastCart/types';
import { TLinkCustumerResponse } from '@mf/common/repositories/bff/cart/linkCustomer/types';

export const CartContextInit = {
  addItemCart: (): Promise<TAddItemResponse | null> => Promise.resolve(null),
  createCart: (): Promise<TCreateCartResponse | null> => Promise.resolve(null),
  createOfflineCart: () => Promise.resolve(null),
  customerId: null,
  getCartDetails: (): Promise<TLastCartResponse | null> =>
    Promise.resolve(null),
  getLastCart: (): Promise<TLastCartResponse | null> => Promise.resolve(null),
  lastCartId: null,
  lastCartItems: [],
  linkCustomerToCart: () => Promise.resolve(null),
  offlineCartId: null,
  removeItemCart: () => Promise.resolve(null),
};

export type CartContextProps = {
  children: React.ReactNode;
  payload: TCartPayload;
};

export type TCartPayload = {
  bffUrl: string;
};

export type CartContextType = {
  addItemCart: (
    cartId: string,
    itemToken: TAddItemPayload,
  ) => Promise<TAddItemResponse | null>;
  createCart: () => Promise<TCreateCartResponse | null>;
  createOfflineCart: () => Promise<TCreateCartResponse | null>;
  customerId: number | null;
  getCartDetails: (cartId: string) => Promise<TLastCartResponse | null>;
  getLastCart: () => Promise<TLastCartResponse | null>;
  lastCartId: string | null;
  lastCartItems: TCartItems[];
  linkCustomerToCart: (cartId: string) => Promise<TLinkCustumerResponse | null>;
  offlineCartId: string | null;
  removeItemCart: (cartId: string, itemId: string) => void;
};
