import axios, { AxiosInstance } from 'axios';
import { newRelicProvider } from '@mf/common/components/Logs/providers/newRelicProvider';
import { TAxiosDefaultConfig } from '../../repositories/aecomStrapiCms/defaults/types';

const setup = (api: AxiosInstance): AxiosInstance => {
  api.defaults.headers.common = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  return interceptors(api);
};

const interceptors = (api: AxiosInstance): AxiosInstance => {
  api.interceptors.response.use(
    (res) => res,
    // eslint-disable-next-line
    async (error) => {
      const { response } = error;

      if (!response) {
        newRelicProvider.logError(error.name, {
          url: error.config?.url,
          method: error.config?.method,
          requestData: error.config?.data,
        });
        return Promise.reject(error);
      }

      newRelicProvider.logError(error.name, {
        status: error.response.status,
        statusText: error.response.statusText || 'Sem statusText',
        url: error.config?.url,
        method: error.config?.method,
        requestData: error.config?.data,
        responseData: error.response.data,
      });
      return Promise.reject(error);
    },
  );

  return api;
};

const auth = (api: AxiosInstance, token?: string): AxiosInstance => {
  // eslint-disable-next-line
  api.interceptors.request.use(async (config) => {
    if (config.headers && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  return api;
};

export const api = ({
  baseUrl,
  token,
  xApiKey,
}: TAxiosDefaultConfig): AxiosInstance => {
  if (xApiKey) {
    const apiAxios = axios.create({
      baseURL: baseUrl,
      headers: {
        'X-API-KEY': xApiKey,
      },
    });
    return setup(auth(apiAxios, token));
  }

  const apiAxios = axios.create({
    baseURL: baseUrl,
  });
  return setup(auth(apiAxios, token));
};
