'use client';

import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useParams } from 'next/navigation';

type LayoutContextData = {
  isMobile: boolean;
  isSmallMobile: boolean;
  isDesktop: boolean;
  showCta: boolean;
  setShowCta: Dispatch<SetStateAction<boolean>>;
  showSearchButton: boolean;
  setShowSearchButton: Dispatch<SetStateAction<boolean>>;
  showSearchPage: boolean;
  setShowSearchPage: Dispatch<SetStateAction<boolean>>;
  hash: string;
};

export type LayoutContextProviderProps = {
  children: ReactNode;
};

export const LayoutContext = createContext({} as LayoutContextData);

/**
 * Based on the user's screen size, returns whether
 * the device should be considered a mobile device
 */
export function LayoutContextProvider({
  children,
}: LayoutContextProviderProps) {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isSmallMobile, setIsSmallMobile] = useState<boolean>(false);
  const [showCta, setShowCta] = useState<boolean>(false);
  const [showSearchButton, setShowSearchButton] = useState<boolean>(false);
  const [showSearchPage, setShowSearchPage] = useState<boolean>(false);
  const [hash, setHash] = useState('');

  const params = useParams();

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsSmallMobile(window.innerWidth < 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!window) {
      return;
    }
    setHash(window.location.hash);
  }, [params, hash]);

  return (
    <LayoutContext.Provider
      value={{
        isMobile,
        isSmallMobile,
        isDesktop: !isMobile,
        showCta,
        setShowCta,
        showSearchButton,
        setShowSearchButton,
        showSearchPage,
        setShowSearchPage,
        hash,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

export const useLayout = () => {
  return useContext(LayoutContext);
};
