import { AxiosInstance } from 'axios';
import { api } from '@mf/common/config/axios/serviceApi';
import { TAxiosDefaultConfig } from '@mf/common/repositories/aecomStrapiCms/defaults/types';
import type { TCreateCartResponse } from './types';

const bffCreateCart = ({ baseUrl, token }: TAxiosDefaultConfig) => {
  const apiBff: AxiosInstance = api({ baseUrl, token });

  const createCart = async (): Promise<TCreateCartResponse | null> => {
    const { data } = await apiBff.post('v1/carts');
    return data as TCreateCartResponse;
  };

  return {
    createCart,
  };
};

export default bffCreateCart;
